@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.navbar {
  display: flex;
  width: 90%;
  margin: 1.4rem auto;
  margin-top: 0;
  padding-top: 1.4rem;
  justify-content: space-between;
  align-items: center;
}
.navbar__logo img {
  width: 250px;
}
.cross-image{
  cursor: pointer;z-index: 4;
  position: absolute;right: 20px;top: 10px;
}
.navbar__links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.7rem;
}
.navbar__links p {
  font-weight: 500;
  cursor: pointer;
  font-size: 1.1rem;
  opacity: 0.7;
  line-height: 33px;
  color: #333333;
}
.btn {
  background: #4087ba;
  box-shadow: 0px 20px 12px rgba(0, 0, 0, 0.02), 0px 9px 9px rgba(0, 0, 0, 0.03),
    0px 2px 5px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03);
  border-radius: 6.69674px;
  color: #fff;
  border: none;
  padding: 10px 22px;
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: -0.02em;
}
button {
  cursor: pointer;
}
.home-page-wrap {
  background-image: url("./assets/landing.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 103vh;
  min-height: 1000px;
  width: 100%;
}
.home-page-heading h1 {
  font-weight: 700;
  font-size: 2.9rem;
  line-height: 56px;
  /* or 111% */
  width: 650px;
  margin: 0 auto;
  text-align: center;
  letter-spacing: -0.02em;
  margin-top: 5rem;
  color: #494343;
}
.home-page-about-us {
  margin-top: 10rem;
  margin-bottom: 10rem;
}
.home-page-container {
  width: 100%;
  height: 100%;
}
.container {
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  width: 85%;
  margin: 0 auto;
}
.home-page-abput-us-right img {
  width: 100%;
  position: relative;
  z-index: -1;
}
.container-title {
  position: absolute;
  left: -220px;
  top: 19%;
  transform: rotate(-90deg);
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 700;
  font-size: 36px;
  line-height: 135%;
  /* identical to box height, or 80px */

  letter-spacing: -0.03em;
  text-transform: capitalize;

  color: #4087ba;
}
.our-prducts .container-title {
  left: -250px;
}
.line {
  width: 100px;
  height: 2px;
  background-color: #4087ba;
}
.home-page-abput-us-left {
  background: #f6f6f6;
  padding: 3rem;
  border-radius: 10px;
  padding-right: 1rem;
}
.home-page-abput-us-left h1 {
  font-weight: 600;
  font-size: 32px;
  /* line-height: 70px; */
  /* or 194% */
  margin-bottom: 1rem;
  letter-spacing: -0.03em;

  color: #343434;
}
.survey-container-left h1 {
  font-weight: 700;
  font-size: 42px;
  letter-spacing: -0.02em;
  opacity: 0.98;
  color: #ffffff;
}
.our-prducts {
  position: relative;
}
.our-products-container {
  width: 70%;
  min-width: 1300px;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 10rem;
  margin-top: 8rem;
}
.our-products-container > div {
  width: 350px;
  padding: 12px;
  padding-bottom: 24px;
  background: #ffffff;
  box-shadow: 0px 0px 99.579px 1.55592px rgba(0, 0, 0, 0.07);
  border-radius: 20.4937px;
  /* margin-bottom: 3rem; */
}
.our-products-card-info {
  padding: 0.4rem;
}
.our-products-card-info-title {
  min-height: 150px;
}
.our-products-card-img {
  background: #4087ba;
  border-radius: 16.8771px;
  margin-bottom: 2rem;
  width: 100%;
  min-height: 205px;
}
.our-products-card-img img {
  position: relative;
  /* top: 30px; */
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}
.our-products-card-info-title h1 {
  font-weight: 600;
  font-size: 22.9422px;
  line-height: 32px;
  /* identical to box height, or 132% */

  letter-spacing: -0.03em;

  color: #343434;
}
.our-products-card-info-title p {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  /* or 143% */

  letter-spacing: -0.01em;

  color: #666666;
}
.survey-container-left p {
  font-weight: 500;
  margin-top: 0.7rem;
  opacity: 0.8;
  margin-bottom: 2.4rem;
  font-size: 18px;
  color: #fff;
  letter-spacing: -0.02em;
}
.home-page-abput-us-left p {
  font-weight: 400;
  font-size: 1rem;
  line-height: 131%;
  margin-bottom: 2rem;
  color: #616161;
}
.survey-container-right img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}
.survey-container {
  margin-top: 10rem;
  margin-bottom: 9rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 0.87fr;
  align-items: center;
  gap: 2rem;
  position: relative;
  justify-content: space-between;
  background: #4087ba;
}
.survey-title {
  color: #fff;
  left: 0%;
  top: unset;
  bottom: 22%;
  left: -115px;
}
.survey-title .line {
  background-color: #fff;
}
.survey-container-left-wrap {
  width: 70%;
  margin: 0 auto;
}
.white-btn {
  background-color: #fff;
  color: #4087ba;
  box-shadow: 0px 26px 15px rgba(0, 0, 0, 0.02),
    0px 11px 11px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.03),
    0px 0px 0px rgba(0, 0, 0, 0.03);
}

.contact-us-container {
  position: relative;
  margin-bottom: 10rem;
  margin-top: 3rem;
}
.contact-us-container-box {
  background: #ffffff;
  box-shadow: 0px 0px 106px 5px rgba(0, 0, 0, 0.07);
  border-radius: 29px;
  padding: 5rem 4rem;
  width: 70%;
  margin: 0 auto;
  text-align: center;
}
.contact-us-container-box h1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 135%;
  /* or 68px */

  text-transform: capitalize;

  color: #515151;
}
.contact-us-container-box p {
  font-size: 1.1rem;
  font-weight: 500;
  padding: 0 5rem;
  margin-top: 1.3rem;
  margin-bottom: 3rem;
  line-height: 135%;
  /* or 30px */

  text-align: center;
  letter-spacing: -0.02em;
  color: #606060;
}
.contact-info {
  width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contact-info-card-icon {
  background: #4087ba;
  border-radius: 11px;
  width: fit-content;
  padding: 1rem;
  padding-bottom: 0.7rem;
}
.contact-info-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-info-card p {
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 135%;
  /* identical to box height, or 30px */

  color: #666666;
}
.contact-us-inputs form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.contact-us-inputs form > div {
  width: 45%;
}
.contact-us-inputs form > div input {
  width: 100%;
  margin-bottom: 3rem;
  background: #f7f7f7;
  border-radius: 12px;
  border: none;
  padding: 20px 25px;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: -0.03em;

  color: #616161;
}
.contact-us-container .container-title {
  left: -115px;
  top: 20rem;
}
.footer {
  background-image: url("./assets/footer.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.footer-container {
  padding-top: 6rem;
  padding-bottom: 15rem;
  width: 90%;
  margin-left: auto;
  color: #fff;
}
.footer-container h1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  /* or 52px */
  margin-bottom: 1.5rem;
  letter-spacing: -0.03em;
  color: #ffffff;
}
.home-page-heading p {
  font-weight: 400;
  font-size: 24px;
  line-height: 135%;
  text-align: center;
  opacity: 0.7;
  margin-top: 1.2rem;
  color: #2e2e2e;
}
.bottom-footer-left > img {
  width: 290px;
}
.bottom-footer-flex {
  display: flex;
  justify-content: space-between;
  padding: 0 5rem;
  padding-top: 4rem;
}
.location {
  display: flex;
  margin-top: 1.6rem;
}
.location p {
  font-weight: 400;
  font-size: 18px;
  line-height: 135%;
  margin-left: 10px;
  /* or 30px */

  color: #ffffff;
}
.footer-btn button {
  background: #ffffff !important;
  padding: 12px 24px !important;
  font-size: 14px;
  box-shadow: 0px 20.8239px 12.0138px rgba(0, 0, 0, 0.02),
    0px 8.81011px 8.81011px rgba(0, 0, 0, 0.03),
    0px 2.40276px 4.80551px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03);
  color: #4087ba !important;
}
.footer-container p {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  opacity: 0.7;
  margin-bottom: 1rem;
  width: 50%;
}

.footer-links {
  margin: 6rem auto;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.copyright p {
  font-weight: 400;
  font-size: 14px;
  line-height: 135%;
  margin-top: 3rem;
  padding-bottom: 2rem;
  text-align: center;

  color: #ffffff;
}
.bottom-footer-right-one h1 {
  font-weight: 600;
  margin-bottom: 1.3rem;
  font-size: 32px;
  line-height: 54px;
  /* identical to box height */

  color: #ffffff;
}
.our-web-app {
  position: relative;
}
.our-web-app__svg {
  position: fixed;z-index: 4;
  bottom: 15px;
  right: 15px;
  filter: drop-shadow(0px 22px 9px rgba(0, 0, 0, 0.01))
    drop-shadow(0px 12px 7px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.09))
    drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
}
.bottom-footer-right-one p {
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 1rem;
  font-size: 17px;
  line-height: 135%;
  display: flex;
  align-items: center;
  /* identical to box height, or 27px */

  color: #e7e7e7;
}
.bottom-footer-right-one p a{ 
  color: #e7e7e7; text-decoration: none;
}
.social-img {
  display: flex;
}
.social-img img {
  margin-right: 8px;
}
.bottom-footer-right-one p img {
  margin-right: 8px;
}
.bottom-footer-right {
  display: flex;
  gap: 3.4rem;
  color: #fff;
  align-items: flex-start;
}

.footer-links-left p {
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 135%;
  width: 40ch;
  margin-top: 1rem;
  opacity: 0.7;
}
.footer-links-right {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.footer-links-right h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 125%;
  /* identical to box height, or 40px */

  letter-spacing: -0.025em;
}
.footer-links-right p {
  font-weight: 500;
  font-size: 1rem;
  line-height: 135%;
  opacity: 0.7;
}
.home-page-more-container {
  overflow: hidden;
  position: relative;
  width: 80%;
  margin: 0 auto;
  position: relative;
  top: -40px;
  /* filter: drop-shadow(0px 0px 103px rgba(0, 0, 0, 0.19)); */
  box-shadow: 0px 0px 103px rgba(0, 0, 0, 0.19);
  border-radius: 17px;
  background-color: #fff;
  padding-top: 2.6rem;
  padding-bottom: 2.8rem;
  text-align: center;
}
.home-page-more-container h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 55px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #353535;
}
.home-page-more-container h1 span {
  font-weight: 700;
  font-size: 62.8168px;
  line-height: 76px;
  letter-spacing: -0.03em;
  color: #4087ba;
}
.home-page-more-container p {
  font-weight: 500;
  z-index: 3;
  position: relative;
  font-size: 1.1rem;
  line-height: 31px;
  color: #838383;
  width: 80%;
  margin: 0 auto;
  margin-top: 0.5rem;
}
.home-page-more-container button {
  margin-top: 2rem;
}
.home-more-img {
  position: absolute;
}
.more-container-img1 {
  left: -6%;
  bottom: -10%;
  transform: rotate(-60deg);
}
.more-container-img2 {
  right: -3%;
  bottom: -10%;
}
.modal-img {
  background: #4087ba;
  border-radius: 16.8771px;
}
.modal-content {
  display: flex;
  position: relative;
  gap: 2rem;
}
.modal-img img {
  padding: 8rem 2rem;
  filter: drop-shadow(0px 152px 61px rgba(0, 0, 0, 0.01))
    drop-shadow(0px 86px 51px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 38px 38px rgba(0, 0, 0, 0.09))
    drop-shadow(0px 10px 21px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
}
.modal-info h1 {
  font-weight: 600;
  font-size: 32px;
  margin-top: 1.5rem;
  line-height: 44px;
  margin-bottom: 1rem;
  /* identical to box height, or 111% */

  letter-spacing: -0.03em;

  color: #343434;
}
.modal-info p {
  font-weight: 400;
  font-size: 18px;
  line-height: 138%;
  /* or 28px */
  padding-right: 3rem;

  color: #454545;
}
.btns .secondary-btn {
  background-color: transparent;
  border: 1.4px solid #4087ba;
  color: #4087ba;
}
.select-service {
  position: relative;
}
.register-input {
  position: relative;
}
.register-open-select {
  padding: 24px 1rem;
  background: #ffffff;
  box-shadow: 0px 94px 37px rgba(0, 0, 0, 0.01),
    0px 53px 32px rgba(0, 0, 0, 0.05), 0px 23px 23px rgba(0, 0, 0, 0.09),
    0px 6px 13px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  height: 320px;
  overflow: hidden;
  overflow-y: auto;
  z-index: 4;
  top: -5px;
  width: 300px;
  left: -5px;
  position: absolute;
}
.register-open-select p:hover,
.active-select {
  background: #ecf1ff;
}
.register-open-select p {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 160% */

  color: rgba(0, 0, 0, 0.8);
}
.btns {
  display: flex;
  gap: 1rem;
  position: absolute;
  bottom: 30px;
}
.modal-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.register-modal-left {
  background-color: #3c37ff;
  border-radius: 16px;
  color: #fff;
  padding: 29px 47px;
}
.register-modal {
  display: grid;
  gap: 3rem;
  grid-template-columns: 0.6fr 1fr;
}
.modal-text h1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 127%;
  margin-top: 8rem;
  margin-bottom: 2rem;
  /* or 63px */

  letter-spacing: -0.02em;

  color: #ffffff;
}
.modal-text p {
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  /* or 30px */

  letter-spacing: -0.02em;

  color: rgba(255, 255, 255, 0.8);
}
.review-card {
  background: #2520e3;
  margin-top: 9rem;
  margin-bottom: 1rem;
  border-radius: 17px;
  padding: 27px 22px;
}
.review-text p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 134% */

  color: #aeb0f7;
}
.review-profile {
  display: flex;
  gap: 0.8rem;
  margin-top: 2rem;
}
.review-profile img {
  width: 40px;
  height: 40px;
}
.review-profile-text h1 {
  font-weight: 400;
  font-size: 17.8626px;
  line-height: 24px;
  /* identical to box height, or 134% */

  color: #ffffff;
}
.review-profile-text p {
  font-weight: 400;
  font-size: 14px;

  color: #aeb0f7;
}
.register-modal-right > h1 {
  font-weight: 600;
  font-size: 24.8183px;
  line-height: 39px;
  margin-top: 3.5rem;
  margin-bottom: 2rem;
}
.register-input {
  margin-bottom: 1.6rem;
}
.register-input p {
  font-weight: 500;
  font-size: 16.6672px;
  line-height: 22px;
  /* identical to box height, or 134% */

  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 11px;
}
.select-service {
  background: #ecf1ff;
  border: 1.2px solid #413df5;
  border-radius: 11px;
  padding: 18px 1rem;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;
  width: fit-content;
  font-weight: 500;
  font-size: 17.2969px;
  line-height: 26px;
  color: #413df5;
}
.register-input input {
  border: 1.2px solid #acacac;
  border-radius: 8px;
  padding: 14px;
  width: 80%;
  font-weight: 500;
  font-size: 14.6672px;
  line-height: 22px;
  /* identical to box height, or 134% */

  color: #404040;
}
.register-btn button {
  position: absolute;
  background: #3c37fd;
  border-radius: 11px;
  font-weight: 500;
  font-size: 15.5177px;
  line-height: 21px;
  padding: 20px 4rem;
  color: #ffffff;
  border: none;
  bottom: 30px;
}
.register-modal-right {
  position: relative;
}
.single-service {
  background: #3e85b9;
  border-radius: 35px;
  width: 97%;
  margin: 0 auto;
  margin-top: 2rem;
  padding: 56px 100px;
  color: #fff;
}
.logo-single img {
  width: 200px;
}
.single-service-content-text h1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 128%;
  margin-top: 7rem;

  letter-spacing: -0.02em;
  margin-bottom: 20px;

  color: #ffffff;
}
.single-service-content-text p {
  font-weight: 400;
  margin-bottom: 3.3rem;
  font-size: 20.8074px;
  line-height: 128%;
  /* or 33px */

  letter-spacing: -0.02em;

  color: rgba(255, 255, 255, 0.8);
}
.single-service-content {
  position: relative;
  z-index: 3;
}
.single-service-content button {
  font-weight: 600;
  font-size: 18.5177px;
  line-height: 21px;
  /* identical to box height, or 134% */
  border: none;

  color: #3e85b9;
  background: #ffffff;
  box-shadow: 0px 92px 37px rgba(1, 0, 51, 0.01),
    0px 51px 31px rgba(1, 0, 51, 0.05), 0px 23px 23px rgba(1, 0, 51, 0.09),
    0px 6px 13px rgba(1, 0, 51, 0.1), 0px 0px 0px rgba(1, 0, 51, 0.1);
  border-radius: 11px;
  padding: 20px 70px;
  margin-bottom: 5rem;
}
.single-service {
  position: relative;
  overflow: hidden;
}
.single-service-content-img {
  position: absolute;
  right: 0px;
  bottom: -100px;
}
.single-service-content-img img {
  width: 114%;
  z-index: -1;
  position: relative;
}
.clean-page__content-img {
  width: 40%;
}
.clean-page__content-img img {
  /* width: 210%; */
}
.ss-page__content-img-steam {
  right: 170px;
}
.ss-page__content-img-steam img {
  width: 190%;
}
.ss-page-desc {
  width: 70%;
  margin: 0 auto;
  margin-top: 5rem;
  padding-bottom: 8rem;
}
.ss-page-desc p {
  font-weight: 500;
  font-size: 18px;
  line-height: 121%;
  /* or 29px */

  text-align: justify;

  color: rgba(0, 0, 0, 0.75);
}
.advantages h1 {
  font-weight: 600;
  font-size: 26px;
  line-height: 131%;
  /* or 47px */
  margin-top: 3rem;
  text-align: justify;

  color: #000000;
}
.advatage-content {
  margin-left: 6rem;
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.advatage-content li {
  font-weight: 500;
  font-size: 18px;
  line-height: 131%;
  /* or 34px */

  color: rgba(0, 0, 0, 0.75);
}
.single-service-img-single {
  width: 80%;
  margin: 0 auto;
}
.single-service-img-single img {
  width: 100%;
  margin: 0 auto;
  margin-top: 6rem;
  text-align: center;
}
.advantage-item h2 {
  margin-top: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.8em;
  font-size: 22px;
  /* or 76px */
  background-color: #ededed;
  border-radius: 7px;
  color: #2f2f2f;
  width: fit-content;
  padding: 3px 11px;
}
.advantage-item p {
  font-weight: 400;
  font-size: 18px;
  line-height: 128%;
  margin-left: 20px;
  /* or 52px */

  color: #2f2f2f;
}
.repeated {
  margin-top: 1rem;
}
.advantages {
  margin-bottom: 2rem;
}
.menu-bar {
  display: none;
}

.brand-modal h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 55px;
  /* identical to box height, or 152% */

  text-align: center;
  letter-spacing: -0.03em;

  color: #413c3c;
  margin-bottom: 2.4rem;
}
.brand-modal img {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding: 0 2rem;
}
.air-audit-img img {
  width: 150%;
}
.air-audit-img {
  right: 10%;
}
.modal-logo img {
  width: 240px;
}
.enlarged-image {
  width: 90% !important;
}
.PneumaticImg img {
  width: 160%;
}
.PneumaticImg {
  right: 10%;
}
.home-page-new-container-flex {
  display: grid;
  gap: 3rem;
  width: 90%;
  margin: 0 auto;
  grid-template-columns: 0.5fr 1fr;
  margin-bottom: 10rem;
  margin-top: 2rem;
}
.landing-btn button {
  background: #4087ba;
  box-shadow: 0px 92px 37px rgba(0, 0, 0, 0.01),
    0px 51px 31px rgba(0, 0, 0, 0.05), 0px 23px 23px rgba(0, 0, 0, 0.09),
    0px 6px 13px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 11px;
  font-weight: 500;
  font-size: 18.52px;
  line-height: 21px;
  /* identical to box height, or 113% */

  color: #ffffff;
  padding: 19px 60px;
  border: none;
  margin-top: 4.5rem;
}
.home-page-new-container-flex-right img {
  width: 100%;
  height: 700px;
  object-fit: cover;
  border-radius: 20px;
  position: relative;
  right: -10%;
}
.home-page-new-container-flex h1 {
  font-weight: 700;
  font-size: 46px;
  margin-top: 5rem;
  line-height: 60px;
  /* or 111% */

  letter-spacing: -0.02em;

  color: #404040;
}
.bottom-footer {
  background-color: #4087ba;
}
.home-page-new-container-flex p {
  margin-top: 1rem;
  font-weight: 400;
  font-size: 24px;
  line-height: 135%;
  /* or 32px */

  color: #404040;
}
#root {
  overflow-x: hidden;
}
@media (max-width: 1350px) {
  .home-page-new-container-flex {
    grid-template-columns: 0.7fr 1fr;
    gap: 2rem;
  }
  .home-page-new-container-flex-right img {
    right: -13%;
  }
  .home-page-new-container-flex h1 {
    font-size: 42px;
    line-height: 50px;
  }
  .home-page-new-container-flex p {
    font-size: 22px;
  }
  .contact-us-container-box {
    width: 80%;
    padding: 3.5rem 2.5rem;
  }
  .our-products-container {
    width: 90%;
    min-width: unset;
    max-width: unset;
  }
  .contact-us-container-box p {
    padding: 0 3rem;
  }
  .more-container-img1 {
    left: -10%;
  }
  .more-container-img2 {
    right: -10%;
  }
  .ss-page-desc {
    width: 80%;
  }
  .advatage-content {
    margin-left: 3rem;
  }
  .single-service-content-text h1 br {
    display: none;
  }
  .ss-page__content-text {
    width: 56ch;
  }
  .single-service {
    padding: 3rem;
  }
  .our-products-container > div {
    width: 30%;
  }
}
@media (max-width: 1050px) {
  .bottom-footer-flex {
    flex-direction: column;
  }
  .home-page-new-container-flex {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .home-page-new-container-flex-right img {
    height: 500px;
  }
  .landing-btn button {
    margin-top: 2.4rem;
    margin-bottom: 4rem;
    padding: 14px 40px;
    font-size: 16px;
  }
  .home-page-new-container-flex p {
    font-size: 20px;
  }
  .home-page-new-container-flex h1 {
    font-size: 36px;
    line-height: 40px;
  }
  .home-page-new-container-flex h1 {
    margin-top: 2rem;
  }
  .home-page-new-container-flex-right img {
    right: 0;
  }
  .modal-text h1 {
    font-size: 30px;
    margin-top: 4rem;
  }
  .review-card {
    margin-top: 5rem;
  }
  .register-modal-left {
    padding: 23px;
  }
  .review-profile-text h1 {
    font-size: 15px;
  }
  .register-modal-right > h1 {
    margin-top: 1.8rem;
  }
  .register-btn button {
    position: relative;
    bottom: unset;
    margin-bottom: 1rem;
  }
  .contact-us-container-box p {
    padding: 0 1rem;
  }
  .contact-us-inputs form > div input {
    padding: 15px 18px;
    font-size: 14px;
    margin-bottom: 1rem;
  }
  .contact-us-container-box h1 {
    font-size: 28px;
  }
  .contact-us-container-box p {
    font-size: 15px;
  }
  .contact-us-inputs {
    margin-bottom: 1.5rem;
  }
  .contact-info-card-icon {
    padding: 0.8rem;
    padding-bottom: 0.5rem;
  }
  .contact-us-inputs form > div {
    width: 48%;
  }
  .home-page-more-container {
    width: 95%;
  }
  .navbar__links {
    gap: 2rem;
  }
  .single-service-content-text h1 {
    margin-top: 4rem;
  }
  .single-service-content-img img {
    width: 95%;
  }
  .single-service-content-img {
    right: -100px;
  }
  .single-service-content button {
    margin-bottom: 1.5rem;
  }
  .single-service {
    margin-top: 1rem;
  }
  .single-service-content-text h1 {
    font-size: 36px;
  }
  .ss-page__content-text {
    width: 50%;
  }
  .single-service-content-text p {
    font-size: 18px;
  }
  .ss-page-desc {
    padding-bottom: 3rem;
  }
  .ss-page__content-img-steam img {
    width: 170%;
  }
  .ss-page__content-img-steam {
    right: 40px;
  }
  .navbar__links,
  .nav-btn {
    display: none;
  }
  .menu-bar {
    display: block;
    cursor: pointer;
  }
  .open-menu {
    position: fixed;
    right: 5%;
    top: 4%;
    background: #ffffff;
    z-index: 4;
    box-shadow: 0px 94px 37px rgba(0, 0, 0, 0.01),
      0px 53px 32px rgba(0, 0, 0, 0.05), 0px 23px 23px rgba(0, 0, 0, 0.09),
      0px 6px 13px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 14px;
    padding: 26px 18px;
    width: 350px;
  }
  .open-menu__links p {
    padding: 8px 10px;
    width: 100%;
    cursor: pointer;
    border-radius: 8px;
  }
  .open-menu__links p:hover {
    background: #f2f2f2;
  }
  .open-menu .nav-btn {
    display: block;
    margin-top: 1.5rem;
  }
  .home-page-abput-us-left p {
    font-size: 15px;
  }

  .home-page-about-us {
    width: 90%;
  }
  .home-page-about-us {
    display: flex;
    flex-direction: column-reverse;
  }
  .home-page-abput-us-left {
    padding: 1.5rem;
  }
  .home-page-abput-us-left h1 {
    font-size: 28px;
  }
}
@media (max-width: 900px) {
  .modal-info h1 {
    font-size: 26px;
    line-height: 30px;
  }
  .modal-info p {
    font-size: 15px;
  }
  .register-input {
    margin-bottom: 1rem;
  }
  .home-page-more-container h1 {
    font-size: 30px;
    line-height: unset;
  }
  .modal-content {
    flex-direction: column;
  }
  .modal-content {
    gap: unset;
  }
  .modal-img {
    margin-bottom: 20px;
  }
  .btns {
    position: unset;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .modal-info p {
    padding-right: unset;
  }
  .modal-info {
    padding: 0 1rem;
  }
  .modal-img img {
    padding: 0;
    text-align: center;
    width: 50%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    align-items: center;
  }
  .register-modal {
    gap: 1.5rem;
  }
  .register-input input {
    width: 95%;
  }
  .ss-page-desc {
    width: 90%;
  }
  .modal-text h1 {
    font-size: 28px;
  }
  .register-btn button {
    padding: 16px 2rem;
  }
  .home-page-more-container p {
    font-size: 1rem;
    line-height: 25px;
  }
  .more-container-img1 img,
  .more-container-img2 img {
    width: 200px;
  }
  .home-page-more-container p {
    width: 95%;
  }
  .our-products-container > div {
    width: 44%;
  }
  .our-products-card-img {
    margin-bottom: 1rem;
  }
  .our-products-container {
    gap: 1.4rem;
  }
  .our-products-card-info-title {
    min-height: 175px;
  }
  .container-title {
    font-size: 24px;
  }
  .our-prducts .container-title {
    left: -180px;
  }
  .home-page-more-container h1 span {
    font-size: 46px;
    line-height: 54px;
  }
  .advatage-content {
    margin-left: 1.5rem;
  }
  .advantages h1 {
    font-size: 22px;
  }
  .advantage-item h2 {
    font-size: 18px;
    margin-top: 1rem;
    margin-bottom: 0.65rem;
  }
  .ss-page-desc p {
    font-size: 16px;
  }
  .footer-container {
    width: 95%;
  }
  .footer-container p {
    width: 80%;
  }
  .footer-links {
    width: 90%;
  }
  .footer-links-left img {
    width: 230px;
  }
  .footer-container {
    padding-bottom: 11rem;
  }
  .footer-links-left p {
    font-size: 0.95rem;
  }
  /* .footer-container h1{
    font-size: 28px;
  } */
  .clean-page__content-img img {
    width: 190%;
  }
  .clean-page__content-img {
    right: 50px;
  }
  .survey-container {
    grid-template-columns: unset;
    display: flex;
    flex-direction: column;
  }
  .survey-container-left-wrap {
    padding-top: 3rem;
    padding-bottom: 2rem;
    width: 90%;
  }
  .survey-container-right img {
    height: 450px;
  }
  .survey-container-left h1 {
    font-size: 32px;
    font-weight: 600;
  }
  .survey-container-left p {
    font-size: 16px;
  }
  .survey-container-right {
    width: 100%;
  }
  .survey-title {
    bottom: 14%;
    left: -100px;
    top: unset;
  }
  .bottom-footer-right {
    justify-content: space-between;
    margin-top: 3rem;
  }
  .bottom-footer-right-one p {
    font-size: 15px;
  }
  .copyright p {
    font-size: 13px;
  }
  .location p {
    font-size: 16px;
  }
}
@media (max-width: 700px) {
  .bottom-footer-flex {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .logo-single img {
    width: 150px;
  }
  .bottom-footer-left > img {
    width: 200px;
  }
  .bottom-footer-right-one h1 {
    font-size: 24px;
  }
  .single-service-content-text h1 {
    font-size: 24px;
    line-height: 29px;
    margin-top: 1rem;
    letter-spacing: -0.1ch;
  }
  .single-service-content-text p {
    font-size: 15px;
    margin-bottom: 1.2rem;
  }
  .single-service {
    padding: 2rem 1.4rem;
    border-radius: 20px;
  }
  .single-service-content-text p br {
    display: none;
  }
  .single-service-content > div:nth-child(2) {
    right: unset;
    left: unset;
  }
  .ss-page-desc {
    margin-top: 1.5rem;
  }
  .ss-page-desc p {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: 17px;
  }

  .single-service-content button {
    padding: 0.8rem 2rem;
    font-size: 15px;
  }
  .modal-info p {
    font-size: 13px;
    line-height: 16px;
    text-align: justify;
  }
  .modal-img {
    min-height: 200px;
  }
  .home-page-abput-us-left button {
    font-size: 15px;
  }
  .modal-img img {
    width: 60%;
    object-fit: cover;
    display: flex;
    height: 100%;
    align-items: flex-end;
  }
  .btns button {
    font-size: 14px;
  }
  .modal-info h1 {
    font-size: 22px;
    line-height: 28px;
    margin-top: 0;
  }
  .our-products-card-info button {
    font-size: 15px;
  }
  .register-modal {
    display: flex;
    flex-direction: column;
  }
  .our-products-card {
    padding-bottom: 18px !important;
  }
  .modal-text h1 {
    margin-bottom: 0.8rem;
    margin-top: 0.8rem;
    font-size: 25px;
    line-height: 26px;
  }
  .modal-text p {
    font-size: 15px;
    font-weight: 400;
  }
  .register-modal-right > h1 {
    font-size: 20px;
    margin-bottom: 1rem;
    margin-top: 0.2rem;
  }
  .select-service {
    padding: 0.4rem 1rem;
  }
  .register-input input {
    font-size: 13px;
    padding: 6px 14px;
  }
  .modal-logo img {
    width: 110px;
  }
  .register-modal-right > h1 {
    margin-bottom: 9px;
  }
  .register-btn button {
    margin-bottom: 8px;
    font-size: 14px;
    padding: 10px 18px;
    margin-top: 15px;
  }
  .select-service {
    font-size: 14px;
  }
  .register-modal-left {
    padding: 1rem 1.3rem;
  }
  .review-card {
    display: none;
  }
  .register-modal-left {
    margin-bottom: 10px;
  }
  .register-modal {
    gap: unset;
  }
  .register-input {
    margin-bottom: 8px;
  }
  .register-modal-right > h1 {
    display: none;
  }
  .register-input p {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .review-card {
    padding: 18px;
  }
  .review-card {
    margin-top: 1.5rem;
  }
  .review-profile {
    margin-top: 0.9rem;
  }
  .register-modal-right {
    padding: 0 1rem;
  }
}
@media (max-width: 600px) {
  .bottom-footer-right-one h1 {
    font-size: 21px;
    margin-bottom: 1rem;
  }
  .bottom-footer-right-one p {
    margin-bottom: 12px;
    font-size: 13px;
  }
  .brand-modal h1 {
    margin-top: 2rem;
    font-size: 26px;
    font-weight: 500;
    line-height: 30px;
  }
  .home-page-new-container-flex h1 {
    font-size: 24px;
    line-height: 29px;
  }
  .home-page-new-container-flex p {
    font-size: 14px;
    opacity: 0.85;
  }
  .home-page-new-container-flex h1 {
    margin-top: 0rem;
  }
  .home-page-new-container-flex {
    margin-bottom: 4.4rem;
  }
  .landing-btn button {
    font-size: 14px;
    padding: 12px 34px;
    margin-bottom: 1rem;
  }
  .home-page-new-container-flex-right img {
    height: 300px;
  }
  .our-products-container {
    margin-top: 4rem;
  }
  .our-products-container,
  .survey-container {
    margin-bottom: 5rem;
  }
  .home-page-abput-us-left h1 {
    font-size: 26px;
    line-height: 36px;
  }
  .contact-us-container-box {
    padding: 2rem 1rem;
    padding-top: 2.5rem;
  }
  .our-products-container > div {
    width: 85%;
  }

  .home-page-about-us .container-title {
    left: -90px;
  }
  .home-page-abput-us-left p {
    text-align: justify;
  }
  .contact-us-container-box h1 {
    font-weight: 600;
  }
  .contact-us-container-box {
    width: 90%;
  }
  .contact-us-container-box p {
    font-size: 14px;
    padding: 0;
    margin-top: 0.8rem;
  }
  .contact-info-card-icon img {
    width: 25px;
  }
  .contact-us-inputs form > div {
    width: 100%;
  }
  .home-page-more-container h1 {
    font-size: 28px;
  }
  .our-products-card-info-title {
    min-height: 100px;
    margin-bottom: 1rem;
  }
  .container-title {
    top: 5%;
  }
  .footer-links {
    display: flex;
    flex-direction: column;
  }
  .footer-links-left p {
    margin-bottom: 2.2rem;
  }
  .footer-links {
    margin-top: 3rem;
  }
  .ss-page__content-text {
    width: unset;
  }
  .advantages h1 {
    text-align: left;
    font-size: 18px;
    margin-top: 1.5rem;
  }
  .single-service-content-text h1 {
    font-size: 24px;
  }
  .advatage-content {
    margin-left: 0.7rem;
  }
  .ss-page__content-img-steam img {
    width: unset;
  }
  .advantage-item h2 {
    font-size: 15.4px;
  }
  .single-service-content-text p {
    font-size: 16px;
  }
  .single-service-content button {
    margin-bottom: 10rem;
  }
  .footer-container h1 {
    font-size: 28px;
    font-weight: 600;
  }
  .footer-container p {
    font-size: 16px;
    font-weight: 400;
  }
  .footer-container {
    padding-bottom: 8rem;
  }
  .home-page-heading p {
    font-size: 18px;
  }
  .home-page-heading p br {
    display: none;
  }
  .home-page-heading h1 {
    font-size: 36px;
    width: unset;
    line-height: 44px;
  }
  .more-container-img1 img {
    width: 140px;
  }
  .home-page-more-container {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
  }
  .our-products-card-info-title h1 {
    font-size: 20px;
    line-height: 24px;
  }
  .our-products-card-info-title p {
    font-size: 14px;
    margin-top: 10px;
    line-height: 17px;
    font-weight: 400;
  }

  .line {
    width: 80px;
  }
  .container-title {
    font-weight: 500;
    font-size: 20px;
  }
  .our-prducts .container-title {
    left: -150px;
  }
  .survey-title {
    top: unset;
    left: -70px;
    bottom: 8%;
  }
  .footer-links-left p {
    width: unset;
  }
  .navbar__logo img {
    width: 170px;
  }
  .home-page-heading h1 {
    font-size: 28px;
    line-height: 34px;
    margin-top: 3.5rem;
  }
  .home-page-heading p {
    line-height: 22px;
  }
  .open-menu {
    width: 250px;
  }
  .nav-btn button {
    /* width: 250px; */
    font-size: 15px;
  }
  .home-page-wrap {
    height: 88vh;
    min-height: 800px;
    background-position: inherit;
  }
  .home-page-abput-us-left p {
    font-size: 14px;
    line-height: 17px;
  }
  .contact-info {
    width: 97%;
  }
  .contact-us-container-box p {
    font-size: 13px;
  }
  .footer-links-right {
    gap: unset;
  }
  .footer-links-right h1 {
    margin-bottom: 10px;
  }
  .footer-container p {
    width: 100%;
  }
  .footer-container {
    padding-top: 4rem;
    padding-bottom: 6rem;
  }
  .footer-container button {
    font-size: 15px;
  }
  .our-products-container {
    gap: unset;
  }
  .our-products-container > div {
    margin-bottom: 15px;
  }
  .footer-links-right p {
    margin-bottom: 8px;
    font-size: 14px;
  }
  .footer-container p {
    opacity: 0.9;
    line-height: 20px;
  }
  .white-btn {
    font-size: 15px;
  }
  .survey-container-left p {
    font-size: 14px;
    line-height: 18px;
  }
  .survey-container-left h1 {
    font-size: 28px;
  }
  .contact-us-container-box {
    border-radius: 15px;
  }
  .survey-container-right img {
    height: 300px;
  }
  .home-page-more-container p {
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    letter-spacing: -0.07ch;
  }
  .more-container-img1 img,
  .more-container-img2 img {
    width: 120px;
  }
  .home-page-more-container button {
    font-size: 14px;
    margin-top: 1rem;
  }
  .home-page-more-container h1 {
    font-size: 24px;
  }
  .home-page-more-container h1 span {
    font-size: 42px;
  }
  .home-page-more-container {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    border: 2px solid rgba(0, 0, 0, 0.05);
    padding-top: 10px;
  }
  .more-container-img1 img {
    width: 90px;
  }
}
